import styled from 'styled-components';

export const FootContainer = styled.div`
  background: linear-gradient(
    rgba(110, 43, 139, 1) 0%,
    rgba(70, 35, 99, 1) 100%
  );
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const FootStyle = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 0 0 90px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 35px;
  }
`;

export const ImageStyle = styled.img`
  width: 100%;
  max-width: 365px;
  height: auto;
  margin-top: -185px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
    margin-top: -175px;
  }
`;

export const ParaText = styled.p`
  margin-bottom: 40px;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 25px;
  line-height: 35px;
  color: #fff;
  @media (max-width: 767px) {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 30px;
  }
  code {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 15px;
    font-family: 'Figtree-Regular', arial, sans-serif;
    color: #fff;
  }
  b {
    font-weight: bold;
  }
`;

export const AnchorButton = styled.a`
  display: inline-block;
  position: relative;
  padding: 15px 45px;
  margin: 0 0 20px 0;
  font-family: 'Figtree-Black', sans-serif;
  font-size: 23px;
  line-height: 23px;
  text-transform: uppercase;
  color: #6e2b8b;
  background: #fec119;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  :hover{
    background-color: #6e2b8b;
    color: #fff;
  }
`;
