import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import React from 'react';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';
import * as Styled from './slpExistingFooterStyle';

const SlpExistingFooter = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.AnchorButton
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.AnchorButton>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.FootContainer>
      <Styled.FootStyle>
        <Styled.ImageStyle src={sectionData?.image?.file?.url} />
        {sectionData?.richText &&
          documentToReactComponents(
            JSON.parse(sectionData?.richText?.raw),
            optionsMainStyle
          )}
      </Styled.FootStyle>
    </Styled.FootContainer>
  );
};

export default SlpExistingFooter;
